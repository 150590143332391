.app {
  background-color: black;
  min-height: 100vh;
}

.top-bar {
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: lightblue;
}

.burger {
  cursor: pointer;
}

.center-block { 
  color: white;
  text-align: center;
  padding: 16px;
}

.logo {
  justify-content: center;
  display: flex;
  height: 75px;
  padding: 15px;
  margin-top: 4%;
}

.launch-button {
  width: 200px;
  height: 50px;
  background-color: black;
  color: whitesmoke;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid #00ffff;
  padding: 8px 16px;
  cursor: pointer;
  margin-top: 4%;
  animation: floating 3s ease-in-out infinite;
}

@keyframes floating {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}